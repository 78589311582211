import React, { Component } from 'react';
import { connect } from 'react-redux';
// import Products from '../products/Products';
// import axios from 'axios';
import NewEditBasketItem from './NewEditBasketItem';
import {
    // BASKETS_LOADING,
    // BASKETS_LOADED,
    // CURRENT_BASKET_LOADING,
    // CURRENT_BASKET_LOADED,
    CURRENT_BASKET_RESET
} from '../../redux/actionsTypes';

export class NewBasketItem extends Component {
    // state = {
    //     id: '',
    //     title: 'My new basket z NewBasketItem',
    //     content: '',
    //     isSaved: false,
    //     // acf: []
    //     // contain: ['{"18":"Cukier"}']
    //     contain: {},
    //     isNewAdded: true
    // }

    render() {
        this.props.dispatch({ type: CURRENT_BASKET_RESET })
        // const { title, content } = this.state;
        // const { contain } = this.state;
        // // let lista = '';
        // // for (const property in contain) {
        // //     lista = lista + `<li>${property}: ${contain[property]}</li>`
        // // } 


        // //console.log(listContain)
        return (
            // <NewEditBasketItem newbasket={this.state} isNewAdded={true} />
            <NewEditBasketItem isNewAdded={true} />
        )
    }
}

export default connect()(NewBasketItem)
