import React, { Component } from 'react';
import BasketProductItem from './BasketProductItem';
import { Link } from "react-router-dom";

export class BasketItem extends Component {

    tagOut = (str) => {
        var regex = /(<([^>]+)>)/ig
        return str.replace(regex, "");
    }

    render() {
        // console.log('BasketItem: ', this.props.basket)
        // const { title, content, acf, id } = this.props.basket;
        const { title, description, contain, id } = this.props.basket;
        // // let acfArr =  Object.entries(acf);
        // let acfArr = []
        // if (acf.contain) {
        //     acfArr = acf.contain;
        // }
        // // else {
        // //     acfArr = [];
        // // }
        // // if (parseInt(acfArr.length) > 0) {
        // // //console.log("acfArr-in if: " + acfArr.length + ": " + JSON.stringify(acfArr))
        // console.log('BasketItem 26 contain:', contain)
        // let acfArr = [];
        // if (contain) acfArr = JSON.parse(contain);

        return (

            <div className="busket-item card"><details>
                <summary>{title}</summary>
                {/* <p>{this.tagOut(description)}</p> */}
                <p>{description}</p>
                <div className="busket-details">

                    {
                        // contain.map(product => (
                        //     <BasketProductItem key={product.ID} product={product} />
                        // )

                        Object.keys(contain).map(function (obKey, obIndex) {
                            // console.log(contain[obKey]);
                            let product = { title: contain[obKey], id: obKey };
                            return <BasketProductItem key={obKey} product={product} />
                        })


                    }
                    <Link to={`/baskets/${id}`} className="nav-link btn btn-small">Edit</Link>

                </div>

            </details></div>
        )
        // }
        // else {
        //     // //console.log("acfArr-in else: " + acfArr.length + ": " + JSON.stringify(acfArr))
        //     return (
        //         <div><details>
        //             <summary>{title.rendered}</summary>

        //             <div>


        //                 <div className="nav-item"><Link to={`/basket/${id}`} className="nav-link">Edit Basket</Link>
        //                 </div>
        //             </div>

        //         </details></div>
        //     )
        // }
    }

}



export default BasketItem
