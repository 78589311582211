import React, { Fragment } from 'react'

// page: { page: 5, per_page: 10, total: "", totalpages: "" }
function Pagination({ pages, setPage }) {
    // //console.log(zm)
    const { page, totalpages } = pages;
    //console.log('pagination: page totalpages', page, totalpages)

    const setNewPage = (e) => {
        e.preventDefault();
        // //console.log('pagination click', e.target.innerHTML);
        setPage(e.target.innerHTML);
    }


    const pageNumbers = []
    let from = page - 5;
    if (from < 1) from = 1;
    let to = from + 10;
    let less = '';
    let more = ''

    if (to + 1 < totalpages) {
        more = <Fragment> <li key={totalpages - 1}>...</li><li key={totalpages}><a href="!#" onClick={setNewPage}>{totalpages}</a></li></Fragment>
    }
    else if (to < totalpages) {
        more = <li key={totalpages}><a href="!#" onClick={setNewPage}> {totalpages} </a></li>
    }
    if (to > totalpages) {
        to = totalpages;
    }
    if (from > totalpages - 11 && totalpages > 10) from = totalpages - 11;
    // for (let i = 1; i <= totalpages; i++) {
    for (let i = from; i <= to; i++) {
        pageNumbers.push(i)
    }
    // if (more) pageNumbers.push(more);
    if (from >= 3) {
        less = <Fragment> <li key={1}><a href="!#" onClick={setNewPage}>1</a></li><li key={2}>...</li></Fragment>
    }
    // else if (from === 3) {
    //     less = <Fragment><li key={1}><a href="!#" onClick={setNewPage}> {1} </a></li><li key={2}>...</li></Fragment>
    // }

    return (
        <nav className="pagination">
            <ul className="center">
                {less}
                {pageNumbers.map(number => {
                    if (number == page) {
                        return (<li className="current-page" key={number}><a href="!#" onClick={setNewPage}>{number}</a></li>)
                    }
                    else {
                        return (<li key={number}><a href="!#" onClick={setNewPage}>{number}</a></li>)
                    }

                })}
                {more}
            </ul>

        </nav>
    )
}

export default Pagination
