import axios from 'axios';
import { globConfig } from '../../Constants';

import {
    AUTH_ERROR,
    // BASKETS_LOADING,
    BASKETS_LOADED,
    CURRENT_BASKET_LOADED,
    CURRENT_BASKET_SAVED,
    REDIRECT_TO
} from "../actionsTypes";


export const fetchBaskets = (per_page = 15, page) => dispatch => {
    axios.defaults.withCredentials = true;
    const config = {
        headers: {
            'Accept': 'application/json',
            "Content-Type": "application/json",
            // 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    };
    var url = globConfig.url.API_URL2;
    // axios.get('http://localhost:8080/wp-json/wp/v2/basket')
    let query = '?per_page=' + per_page + '&page=' + page;
    // axios.get('http://localhost:8000/sanctum/csrf-cookie').then(response => {

    // console.log(response);
    // axios.get(url + '/baskets' + query, config)
    axios.get(url + '/baskets', config)
        .then(res => {
            let newRes = {
                // baskets: res.data,
                baskets: res.data.baskets.data,
                // pages: { page: page, per_page: per_page, total: res.headers["x-wp-total"], totalpages: res.headers["x-wp-totalpages"] }
                pages: { page: res.data.baskets.current_page, per_page: res.data.baskets.per_page, total: res.data.baskets.total, totalpages: res.data.baskets.last_page }

            };
            // console.log('actions baskets fetchBasket newRes', newRes)
            dispatch({
                type: BASKETS_LOADED,
                // payload: res.data
                payload: newRes
            });
        })
        // })
        .catch(err => {
            // console.log('actions baskets err:', err.response.status, typeof (err.response.status));
            if (err.response.status == 401) {
                dispatch({
                    type: REDIRECT_TO,
                    payload: '/baskets'
                });

            };
        });

    // //console.log('Products headers: ', this.state.headers)

}

export const fetchCurrentBasket = (basketId) => dispatch => {
    const configHeader = makeHeaders()

    const url = globConfig.url.API_URL2;
    // axios.get(`http://localhost:8080/wp-json/wp/v2/basket/${this.props.match.params.id}`, configHeader)
    // axios.get(`http://localhost:8080/wp-json/wp/v2/basket/${this.props.match.params.id}`)
    // axios.get(`http://localhost:8080/wp-json/wp/v2/basket/${basketId}`)
    axios.get(`${url}/baskets/${basketId}`)
        // .then(res => //console.log(res))
        .then(res => {
            // let con = {};
            // console.log(res.data.basket);
            // if (res.data.acf.contain) {
            //     res.data.acf.contain.map(product => (con[product.ID] = product.post_title));
            // }

            let newRes = {
                currentBasket: {
                    basketid: res.data.basket.id,
                    title: res.data.basket.title,
                    description: res.data.basket.description,
                    // content: this.tagOut(res.data.content.rendered),
                    // content: tagOut(res.data.content.rendered),
                    contain: res.data.basket.contain
                    // isLoaded: true,
                }
            };

            dispatch({
                type: CURRENT_BASKET_LOADED,
                // payload: res.data
                payload: newRes
            });

            // //console.log(res.data.acf.contain);
            // //console.log(con);
        })
        .catch(err => console.log(err));
}

//if basketId update else create new
export const saveBasket = (basket, basketId = null) => dispatch => {
    // console.log('actions basket saveBasket', basket)


    // const { title, content, contain } = basket;
    const { title, description, contain } = basket;
    // console.log('actions basket saveBasket contain', JSON.stringify(contain), contain);

    // let arr = [];
    // for (const property in contain) {
    //     arr.push({ "ID": property })

    // }


    // let contObj = {};
    // contain.forEach((item, index) => { contObj[index.toString()] = item });

    const newbasket =
    {
        "basketid": basketId,
        "title": title,
        "description": description,
        // "status": "publish",
        // "contain": contObj
        "contain": contain
    }
    const newdata = JSON.stringify(newbasket);
    // console.log('actions basket saveBasket', newbasket, newdata);
    // this.props.addLead(lead);
    const config = {
        "headers": {
            "Content-Type": "application/json"
        }
    };
    // let token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9sb2NhbGhvc3Q6ODA4MCIsImlhdCI6MTU4MDE0MDk1MSwibmJmIjoxNTgwMTQwOTUxLCJleHAiOjE1ODA3NDU3NTEsImRhdGEiOnsidXNlciI6eyJpZCI6IjEifX19.JkbWHC2nNRb36W9DhY0cYqu0Dnl3m_xJ8HGvFpIKzUs"
    let token = localStorage.getItem("token");
    config.headers["Authorization"] = `Bearer ${token}`;

    let url = globConfig.url.API_URL2;
    // url = url + `/baskets/${basketId}`;
    url = url + `/baskets`;
    // console.log(url);

    // axios.post(url, newdata, config)
    axios.post(url, newdata, config)
        .then(res => {
            // console.log('actions baskets 135: ', res);
            // eslint-disable-next-line 
            if (res.status == "200" || res.status == "201") {
                // console.log('actions baskets saveBasket 138: ', res.data.basket.id)
                dispatch({
                    type: CURRENT_BASKET_SAVED,
                    // payload: res.data
                    payload: { currentBasket: { ...basket, basketid: res.data.basket.id } }
                });
            }
        })
        .catch(err => {
            console.log(err);
            if (err.response.status == 401) {
                dispatch({
                    type: AUTH_ERROR
                });
            };
        });
    //console.log("submit");
}

function tagOut(str) {
    var regex = /(<([^>]+)>)/ig
    return str.replace(regex, "");
}

function makeHeaders() {
    const config = {
        "headers": {
            "Content-Type": "application/json"
        }
    };
    // let token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9sb2NhbGhvc3Q6ODA4MCIsImlhdCI6MTYwNzc1NDY2NCwibmJmIjoxNjA3NzU0NjY0LCJleHAiOjE2MDgzNTk0NjQsImRhdGEiOnsidXNlciI6eyJpZCI6IjEifX19.FElFkgm6C6-yLLeG2MGa-m9bCEowB6ztWjcEhlMM-kY"
    // config.headers["Authorization"] = `Bearer ${token}`;
    let token = localStorage.getItem("token");
    config.headers["Authorization"] = `Bearer ${token}`;
    return config;
}