import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
// import { register } from '../../redux/actions/Auth';
import { register } from '../../redux/actions/Auth';
// import axios from 'axios';
// import { globConfig } from '../../Constants'

export class Register extends Component {
    state = {
        username: '',
        email: '',
        password: '',
        password2: ''
    }

    onSubmit = e => {
        e.preventDefault();
        //console.log('submit');
        this.props.dispatch(register(this.state.username, this.state.email, this.state.password, this.state.password2));
    };

    // // REGISTER USER
    // register = (username, email, password) => {
    //     // Headers
    //     const config = {
    //         headers: {
    //             "Content-Type": "application/json"
    //         }
    //     };
    //     const url = globConfig.url.API_URL_USERS_REGISTER;
    //     // http://localhost:8080/wp-json/wp/v2/users/register
    //     // Request Body
    //     const body = JSON.stringify({ username, email, password });
    //     axios
    //         // .post("http://localhost:8080/wp-json/jwt-auth/v1/token", body, config)
    //         .post(url, body, config)
    //         .then(res => {
    //             this.props.dispatch(login(username, password));
    //             // dispatch({
    //             //     type: REGISTER_SUCCESS,
    //             //     payload: res.data
    //             // });
    //         })
    //         .catch(err => {
    //             //   dispatch(returnErrors(err.response.data, err.response.status));
    //             // dispatch({
    //             //     type: REGISTER_FAIL
    //             // });
    //         });
    // };


    onChange = e => this.setState({
        [e.target.name]: e.target.value
    });

    render() {
        if (this.props.isAuthenticated) {
            // if (this.isAuthenticated) {
            return <Redirect to="/" />
        }
        const { username, email, password, password2 } = this.state;
        return (
            <div className="register col-md-6 m-auto">
                <div className="card card-body mt-5">
                    <h2 className="text-center">Register</h2>
                    <form onSubmit={this.onSubmit}>
                        <div className="form-group">
                            <label>Username</label>
                            <input
                                type="text"
                                className="form-control"
                                name="username"
                                onChange={this.onChange}
                                value={username}
                            />
                        </div>
                        <div className="form-group">
                            <label>Email</label>
                            <input
                                type="email"
                                className="form-control"
                                name="email"
                                onChange={this.onChange}
                                value={email}
                            />
                        </div>
                        <div className="form-group">
                            <label>Password</label>
                            <input
                                type="password"
                                className="form-control"
                                name="password"
                                onChange={this.onChange}
                                value={password}
                            />
                        </div>
                        <div className="form-group">
                            <label>Confirm Password</label>
                            <input
                                type="password"
                                className="form-control"
                                name="password2"
                                onChange={this.onChange}
                                value={password2}
                            />
                        </div>
                        <div className="form-group">
                            <button type="submit" className="btn btn-primary">
                                Register
                  </button>
                        </div>
                        <p>
                            Already have an account? <Link to="/login">Login</Link>
                        </p>
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated
});

// export default Register
export default connect(mapStateToProps)(Register);
