import {
    PRODUCTS_LOADING,
    PRODUCTS_LOADED
} from '../actionsTypes';

const initialState = {
    products: [],
    // headers: '',
    pages: { page: 1, per_page: 10, total: "", totalpages: "", searchName: "" },
    isLoaded: false
}

export default function (state = initialState, action) {
    switch (action.type) {
        case PRODUCTS_LOADING:
            return {
                ...state,
                isLoaded: false
            }
        case PRODUCTS_LOADED:
            //console.log('reducers products action.payload:', action.payload)
            return {
                ...state,
                products: action.payload.products,
                pages: action.payload.pages,
                // headers: action.payload.headers,
                // pages: { page: this.state.pages.page, per_page: this.state.pages.per_page, total: res.headers["x-wp-total"], totalpages: res.headers["x-wp-totalpages"] },
                isLoaded: true
            };
        // case LOGIN_SUCCESS:
        //     //console.log("in auth LOGIN_SUCCESS")
        //     localStorage.setItem("token", action.payload.token);
        //     localStorage.setItem("isAuthenticated", true);
        //     localStorage.setItem("user", action.payload.user_display_name);
        //     return {
        //         ...state,
        //         user: action.payload.user_display_name,
        //         isAuthenticated: true,
        //         isLoading: false
        //     };
        // case AUTH_ERROR:
        // case LOGIN_FAIL:
        // case LOGIN_LOGOUT:
        //     localStorage.removeItem('token');
        //     localStorage.removeItem('user');
        //     localStorage.removeItem('isAuthenticated');
        //     return {
        //         ...state,
        //         // token: null,
        //         user: null,
        //         isAuthenticated: false,
        //         isLoading: false
        //     };
        default:
            return state;
    }
}