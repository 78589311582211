import React, { Component } from 'react';
import NutrientItem from './NutrientItem';

export class ProductItem extends Component {
    render() {
        // console.log('ProductItem state: ', this.props);
        // const { title, acf, id } = this.props.product;
        const { name, id } = this.props.product;
        // let acfArr = Object.entries(acf);
        let acfArr = Object.entries(this.props.product);
        return (
            <div className="product-item">
                {/* <em keyvalue={id} onClick={this.props.addToBasket}>{title.rendered}</em> */}
                <em keyvalue={id} onClick={this.props.addToBasket}>{name}</em>

                <small>
                    {acfArr.map((nutrient, indeks) => (
                        <NutrientItem key={indeks} nutrient={nutrient} />
                    )
                    )
                    }
                </small>

            </div>
        )
    }

}



export default ProductItem
