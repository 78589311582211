import React, { Component } from 'react';
import { connect } from 'react-redux';
// import Products from '../products/Products';
// import axios from 'axios';
import NewEditBasketItem from './NewEditBasketItem'
import { fetchCurrentBasket } from '../../redux/actions/Baskets'
import {
    // BASKETS_LOADING,
    // BASKETS_LOADED,
    CURRENT_BASKET_LOADING,
    // CURRENT_BASKET_LOADED
} from '../../redux/actionsTypes';

export class EditBasketItem extends Component {
    // state = {
    //     // id: '',
    //     title: 'My new basket',
    //     content: '',
    //     isSaved: false,
    //     // acf: []
    //     // contain: ['{"18":"Cukier"}']
    //     contain: {},
    //     isLoaded: false,
    //     isAdded: false
    // }
    componentDidMount() {
        // //console.log('editBasketItem componentDidMounr isLoaded', this.props.isLoaded)
        this.props.dispatch({ type: CURRENT_BASKET_LOADING })
        this.props.dispatch(fetchCurrentBasket(this.props.match.params.id))
        // const config = {
        //     "headers": {
        //         "Content-Type": "application/json"
        //     }
        // };
        // let token = "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOlwvXC9sb2NhbGhvc3Q6ODA4MCIsImlhdCI6MTYwNzc1NDY2NCwibmJmIjoxNjA3NzU0NjY0LCJleHAiOjE2MDgzNTk0NjQsImRhdGEiOnsidXNlciI6eyJpZCI6IjEifX19.FElFkgm6C6-yLLeG2MGa-m9bCEowB6ztWjcEhlMM-kY"
        // config.headers["Authorization"] = `Bearer ${token}`;

        // // axios.get(`http://localhost:8080/wp-json/wp/v2/basket/${this.props.match.params.id}`, config)
        // axios.get(`http://localhost:8080/wp-json/wp/v2/basket/${this.props.match.params.id}`)
        //     // .then(res => //console.log(res))
        //     .then(res => {
        //         let con = {};
        //         //console.log(res.data.acf.contain);
        //         if (res.data.acf.contain) {
        //             res.data.acf.contain.map(product => (con[product.ID] = product.post_title));
        //         }
        //         this.setState({
        //             title: res.data.title.rendered,
        //             // content: res.data.content.rendered,
        //             content: this.tagOut(res.data.content.rendered),
        //             contain: con,
        //             isLoaded: true,
        //             basketid: this.props.match.params.id
        //         });
        //         //console.log(res.data.acf.contain);
        //         //console.log(con);
        //     })
        //     .catch(err => //console.log(err));
    }
    tagOut(str) {
        var regex = /(<([^>]+)>)/ig
        return str.replace(regex, "");
    }

    render() {
        // const { currentBasket, isLoaded } = this.props;
        const { isLoaded } = this.props;
        // const { title, content } = this.state;
        // const { contain } = this.state;
        // // let lista = '';
        // // for (const property in contain) {
        // //     lista = lista + `<li>${property}: ${contain[property]}</li>`
        // // } 


        //console.log('editbasketitem render this.props.match.params.id this.props', this.props.match.params.id, this.props)
        // if (this.state.isLoaded) {
        if (isLoaded) {
            return (
                // <NewEditBasketItem key={this.props.match.params.id} newbasket={currentBasket} />
                <NewEditBasketItem key={this.props.currentBasket.basketid} />
            )
        }
        return (
            <div>Is loading...</div>
        )
    }
}

const mapStateToProps = (state) => ({
    currentBasket: state.baskets.currentBasket,
    isLoaded: state.baskets.isLoaded
})

export default connect(mapStateToProps)(EditBasketItem)


