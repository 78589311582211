import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { connect } from 'react-redux';
// import { LOGIN_LOGOUT } from '../../redux/actionsTypes';
import { logout, getuser } from '../../redux/actions/Auth';

import M from 'materialize-css'

export class Header extends Component {
  componentDidMount() {
    M.Sidenav.init(document.querySelector('.sidenav'))
    //console.log('Header')
    this.props.dispatch(getuser());

  }
  // state = {
  //   isAuthenticated: null,
  //   user: ''
  // }

  logout = () => {
    // this.props.dispatch({
    //   type: LOGIN_LOGOUT
    // });
    this.props.dispatch(logout());

  }


  render() {
    const { isAuthenticated, user, alert } = this.props;
    //console.log(this.props);
    let log;
    // let log2;
    // M.toast({ html: 'I am a toast!', displayLength: 888000, classes: 'alert-error' });
    // M.toast({ html: 'I am a toast!', displayLength: 888000, classes: 'alert-success' });
    // M.toast({ html: 'I am a toast!', displayLength: 888000, classes: 'alert-info' });
    // M.toast({ html: 'I am a toast!', displayLength: 888000, classes: 'alert-error rounded' });
    // M.toast({ html: 'I am a toast!', displayLength: 888000, classes: 'alert-success rounded' });
    // M.toast({ html: 'I am a toast!', displayLength: 888000, classes: 'alert-info rounded' });
    let alertClasses = 'rounded alert-' + alert.type;
    if (alert.msg) {
      M.toast({ html: alert.msg, displayLength: 8000, classes: alertClasses });
    }

    if (isAuthenticated) {
      log = <li className="nav-item"><button onClick={this.logout} className="nav-link btn btn-info btn-sm text-light"><i className="fas fa-sign-out-alt"></i> Logout({user}) </button></li>

    }
    else {
      log = <Fragment><li><Link to="/register" className="nav-link">Register</Link></li><li><Link to="/login" className="nav-link_">Login</Link></li></Fragment>;
    }
    if (isAuthenticated) {
      return (
        <Fragment>
          <nav className="nav">
            <div className="nav-wrapper container">

              <a href="/" className="brand-logo"><i className="fas fa-seedling"></i><span
                className="hide-on-small-only">Diet</span>
              </a>
              <a href="/" data-target="mobile-men" className="sidenav-trigger show-on-small"><i className="fas fa-bars"></i></a>
              <ul id="nav-mobile" className="right">
                <li className="hide-on-med-and-down"><Link to="/" className="nav-link">Products</Link></li>
                <li className="hide-on-med-and-down"><Link to="/baskets" className="nav-link">Baskets</Link></li>
                <li className="hide-on-med-and-down"><Link to="/newbasket" className="nav-link">Add Basket</Link></li>

                {log}

              </ul>
            </div>
          </nav >
          <ul className="sidenav" id="mobile-men">
            <li><Link to="/" className="nav-link">Products</Link></li>
            <li><Link to="/baskets" className="nav-link">Baskets</Link></li>
            <li><Link to="/newbasket" className="nav-link">Add Basket</Link></li>

            {log}
          </ul>
        </Fragment>
      )
    }
    return (
      <Fragment>
        <nav className="nav">
          <div className="nav-wrapper container">

            <a href="/" className="brand-logo"><i className="fas fa-seedling"></i><span
              className="hide-on-small-only">Diet</span>
            </a>
            <a href="/" data-target="mobile-men" className="sidenav-trigger show-on-small"><i className="fas fa-bars"></i></a>
            <ul id="nav-mobile" className="right">
              {/* <li className="hide-on-med-and-down"><Link to="/" className="nav-link">Products</Link></li> */}
              {/* <li className="hide-on-med-and-down"><Link to="/basket" className="nav-link">Baskets</Link></li> */}
              {/* <li className="hide-on-med-and-down"><Link to="/newbasket" className="nav-link">Add Basket</Link></li> */}

              {log}

            </ul>
          </div>
        </nav >
        <ul className="sidenav" id="mobile-men">
          {/* <li><Link to="/" className="nav-link">Products</Link></li> */}
          {/* <li><Link to="/basket" className="nav-link">Baskets</Link></li> */}
          {/* <li><Link to="/newbasket" className="nav-link">Add Basket</Link></li> */}

          {log}
        </ul>
      </Fragment>

    )


  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
  alert: state.auth.alert
});

export default connect(mapStateToProps)(Header)
