export const GET_LEADS = "GET_LEADS";
export const DELETE_LEAD = "DELETE_LEAD";
export const ADD_LEAD = "ADD_LEAD";
export const GET_ERRORS = "GET_ERRORS";
// export const CET_MESSAGES = "GET_MESSAGES";
export const CREATE_MESSAGE = "CREATE_MESSAGE";
export const USER_LOADING = "USER_LOADING";
export const USER_LOADED = "USER_LOADED";
export const AUTH_ERROR = "AUTH_ERROR";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
// export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGIN_LOGOUT = "LOGIN_LOGOUT";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const CLEAR_LEADS = 'CLEAR_LEADS';
export const PRODUCTS_LOADING = 'PRODUCTS_LOADING';
export const PRODUCTS_LOADED = 'PRODUCTS_LOADED';
export const BASKETS_LOADING = 'BASKETS_LOADING';
export const BASKETS_LOADED = 'BASKETS_LOADED';
export const CURRENT_BASKET_LOADING = 'CURRENT_BASKET_LOADING'
export const CURRENT_BASKET_LOADED = 'CURRENT_BASKET_LOADED'
export const CURRENT_BASKET_SAVED = 'CURRENT_BASKET_SAVED'
export const CURRENT_BASKET_NOTSAVED = 'CURRENT_BASKET_NOTSAVED'
export const CURRENT_BASKET_RESET = 'CURRENT_BASKET_RESET'
export const REDIRECT_TO = 'REDIRECT_TO'
export const ALERT_USER = 'ALERT_USER'

