import {
    USER_LOADED,
    USER_LOADING,
    AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    LOGIN_LOGOUT,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    REDIRECT_TO,
    ALERT_USER
} from '../actionsTypes';

const initialState = {
    // isAuthenticated: localStorage.getItem("isAuthenticated"),
    isAuthenticated: false,
    // user: localStorage.getItem("user"),
    user: '',
    // isLoading: localStorage.getItem("isAuthenticated")
    isLoading: true,
    redirectFromLogin: '/',
    alert: { type: '', msg: '' }
};

export default function (state = initialState, action) {
    switch (action.type) {
        // case USER_LOADING:
        //     return {
        //         ...state,
        //         isLoading: true
        //     }
        // case USER_LOADED:
        //     return {
        //         ...state,
        //         isAuthenticated: true,
        //         isLoading: false,
        //         user: action.payload
        //     };
        case LOGIN_SUCCESS:
            // //console.log("in auth LOGIN_SUCCESS")
            // // localStorage.setItem("token", action.payload.token); //z laravel niepotrzebne
            // localStorage.setItem("isAuthenticated", true);
            // // localStorage.setItem("user", action.payload.user_display_name);
            // localStorage.setItem("user", action.payload.username); //po co?
            return {
                ...state,
                user: action.payload.username,
                isAuthenticated: true,
                isLoading: false,
                alert: { type: 'success', msg: 'Login success' }
            };
        // case REGISTER_SUCCESS: //jezeli zalogowany po rejestracji to wyzej odeslac

        //     localStorage.setItem("isAuthenticated", true);

        //     localStorage.setItem("user", action.payload.username); //po co?
        //     return {
        //         ...state,
        //         user: action.payload.username,
        //         isAuthenticated: true,
        //         isLoading: false
        //     };
        case AUTH_ERROR:
        case LOGIN_FAIL:
        case LOGIN_LOGOUT:
            localStorage.removeItem('token');
            localStorage.removeItem('user');
            localStorage.removeItem('isAuthenticated');
            return {
                ...state,
                // token: null,
                user: null,
                isAuthenticated: false,
                isLoading: false,
                alert: { type: 'info', msg: 'You are loged out' }
            };
        case REDIRECT_TO:
            return {
                ...state,
                redirectFromLogin: action.payload
            };
        case ALERT_USER:
            return {
                ...state,
                alert: action.payload
            }
        default:
            return state;
    }
}
