import React, { Component } from 'react';
import { connect } from 'react-redux';
// import axios from 'axios';
import ProductItem from './ProductItem';
// import { globConfig } from '../../Constants';
import Pagination from '../layout/Pagination';
import { fetchProducts, searchProducts } from '../../redux/actions/Products';
import SearchInput from './SearchInput';


export class Products extends Component {
    constructor(props) {
        super(props);
        this.setPage = this.setPage.bind(this)
    }
    // state = {
    //     products: [],
    //     headers: '',
    //     pages: { page: 1, per_page: 20, total: "", totalpages: "" },
    //     isLoaded: false
    // }

    // proxy z package jest ignorowane?
    componentDidMount() {
        // const { per_page, page } = this.props.pages
        const { per_page } = this.props.pages
        this.props.dispatch(fetchProducts(per_page, 1));
        // var url = globConfig.url.API_URL;
        // // axios.get('http://localhost:8080/wp-json/wp/v2/products')
        // let query = '?per_page=' + this.state.pages.per_page + '&page=' + this.state.pages.page;
        // axios.get(url + '/products' + query)
        //     .then(res => this.setState({
        //         products: res.data,
        //         headers: res.headers,
        //         pages: { page: this.state.pages.page, per_page: this.state.pages.per_page, total: res.headers["x-wp-total"], totalpages: res.headers["x-wp-totalpages"] },
        //         isLoaded: true
        //     }))
        //     .catch(err => //console.log(err));

        // // //console.log('Products headers: ', this.state.headers)
    }

    setPage(page) {
        // let newState = this.state
        // newState.pages.page = page
        // this.setState(newState)
        // //console.log('aaa', this.state)
        // //console.log('aaa', this.props)
        const { per_page, searchName } = this.props.pages
        // this.props.dispatch(fetchProducts(per_page, page));
        this.props.dispatch(searchProducts(per_page, page, searchName));
    }

    render() {
        // console.log('Products state: ', this.props);
        const { products, pages, isLoaded } = this.props;
        if (isLoaded) {
            return (
                <div>
                    <SearchInput />
                    {
                        products.map(product => (
                            // <h4>{product.title.rendered}</h4>
                            <ProductItem key={product.id} product={product} addToBasket={this.props.addToBasket}></ProductItem>
                        ))
                    }
                    <Pagination pages={pages} setPage={this.setPage} />
                </div>
            );
        }
        else {
            return (
                <div>Loading...</div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    products: state.products.products,
    pages: state.products.pages,
    isLoaded: state.products.isLoaded

})

export default connect(mapStateToProps)(Products)
