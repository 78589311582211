import React, { Component } from 'react';
import { Link, Redirect } from "react-router-dom";
import { connect } from 'react-redux';
// import PropTypes from 'prop-types';
import { login } from '../../redux/actions/Auth';
// import axios from 'axios';
// import { globConfig } from '../../Constants';
// import {
//     // USER_LOADED,
//     // USER_LOADING,
//     // AUTH_ERROR,
//     LOGIN_SUCCESS,
//     LOGIN_FAIL
//     //   LOGOUT_SUCCESS,
//     //   REGISTER_SUCCESS,
//     //   REGISTER_FAIL
// } from "../../redux/actionsTypes";


export class Login extends Component {
    state = {
        // username: '',
        email: '',
        password: ''
        // isLoading: true,
        // isAuthenticated: false
    }

    // static propTypes = {
    //     login: PropTypes.func.isRequired,
    //     isAuthenticated: PropTypes.bool
    // }

    onSubmit = e => {
        e.preventDefault();
        // this.props.dispatch(login(this.state.username, this.state.password));
        this.props.dispatch(login(this.state.email, this.state.password));
        // this.login(this.state.username, this.state.password);
        // this.props.dispatch(login());
    };

    onChange = e => this.setState({
        [e.target.name]: e.target.value
    });

    // // LOGIN USER
    // login = (username, password) => {
    //     // Headers
    //     const config = {
    //         headers: {
    //             "Content-Type": "application/json"
    //         }
    //     };
    //     const url = globConfig.url.API_URL_USERS;

    //     // Request Body
    //     const body = JSON.stringify({ username, password });

    //     axios
    //         // .post("http://localhost:8080/wp-json/jwt-auth/v1/token", body, config)
    //         .post(url, body, config)
    //         .then(res => {
    //             // localStorage.setItem("token", res.data.token);
    //             //console.log("token: " + res.data.token);
    //             // this.setState({
    //             //     isAuthenticated: true,
    //             //     isLoading: false
    //             // })
    //             this.props.dispatch({
    //                 type: LOGIN_SUCCESS,
    //                 payload: res.data
    //             });
    //         })
    //         .catch(err => {
    //             // dispatch(returnErrors(err.response.data, err.response.status));
    //             this.props.dispatch({
    //                 type: LOGIN_FAIL
    //             });
    //         });
    // };


    render() {
        if (this.props.isAuthenticated) {
            // if (this.isAuthenticated) {
            // return <Redirect to="/" />
            return <Redirect to={this.props.redirectFromLogin} />
        }
        // const { username, password } = this.state;
        const { email, password } = this.state;
        return (
            <div className="login col-md-6 m-auto">
                <div className="card card-body mt-5">
                    <h2 className="text-center">Login</h2>
                    <form onSubmit={this.onSubmit}>
                        <div className="form-group">
                            <label>Email</label>
                            <input
                                type="text"
                                className="form-control"
                                name="email"
                                onChange={this.onChange}
                                value={email}
                            />
                        </div>

                        <div className="form-group">
                            <label>Password</label>
                            <input
                                type="password"
                                className="form-control"
                                name="password"
                                onChange={this.onChange}
                                value={password}
                            />
                        </div>

                        <div className="form-group">
                            <button type="submit" className="btn btn-primary">
                                Login
                  </button>
                        </div>
                        <p>
                            Don't have an account? <Link to="/register">Register</Link>
                        </p>
                    </form>
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    redirectFromLogin: state.auth.redirectFromLogin
});

// export default connect(mapStateToProps, { login })(Login);
export default connect(mapStateToProps)(Login);
// export default Login;
