import axios from "axios";
// import { returnErrors } from "./messages";
import { globConfig } from '../../Constants';

import {
    //   USER_LOADED,
    //   USER_LOADING,
    //   AUTH_ERROR,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    // LOGOUT_SUCCESS,
    REGISTER_SUCCESS,
    REGISTER_FAIL,
    LOGIN_LOGOUT,
    ALERT_USER
} from "../actionsTypes";

// // CHECK TOKEN & LOAD USER
// export const loadUser = () => (dispatch, getState) => {
//   // User Loading
//   dispatch({ type: USER_LOADING });

//   // Get token from state
//   const token = getState().auth.token;

//   //Headers
//   const config = {
//       Headers: {
//           "Content-Type": "application/json"
//       }
//   };

//   //If token, add to headers config
//   if (token) {
//       config.headers["Authorization"] = `Token ${token}`;
//   }

//   axios.get("/api/auth/user", config) //tokenConfig(getState))
//     .then(res => {
//       dispatch({
//         type: USER_LOADED,
//         payload: res.data
//       });
//     })
//     .catch(err => {
//       dispatch(returnErrors(err.response.data, err.response.status));
//       dispatch({
//         type: AUTH_ERROR
//       });
//     });
// };
//GET USER
export const getuser = (email = '', password = '') => dispatch => {
    axios.defaults.withCredentials = true;
    // Headers
    const config = {
        headers: {
            'Accept': 'application/json',
            "Content-Type": "application/json",
        }
    };
    const url = globConfig.url.API_URL_USERS2;
    const body = JSON.stringify({ email, password });
    axios.get(url + '/sanctum/csrf-cookie').then(response => {
        // Login... 
        // console.log(response);
        axios
            .post(url + '/login', body, config) //api/user ?
            .then(res => {
                console.log('get user res:', res);
                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: res.data
                });
            })
            .catch(err => {
                //   dispatch(returnErrors(err.response.data, err.response.status));
                console.log('auth getuser: ', err.response.data, err.response.status);
                dispatch({
                    // type: LOGIN_FAIL,
                    type: ALERT_USER,
                    // payload: { type: 'error', msg: err.response.data.message }
                    payload: { type: 'error', msg: 'You need to log in' }
                });
                // for (const [key, value] of Object.entries(err.response.data.errors)) {
                //     // console.log(`${key}: ${value}`);
                //     dispatch({
                //         // type: LOGIN_FAIL,
                //         type: ALERT_USER,
                //         payload: { type: 'error', msg: `${key}: ${value}` }
                //     });
                // }
            });
    });
};

// LOGIN USER
export const login = (email, password) => dispatch => {
    axios.defaults.withCredentials = true;
    // Headers
    const config = {
        headers: {
            'Accept': 'application/json',
            "Content-Type": "application/json",
            // 'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        }
    };


    // const url = globConfig.url.API_URL_USERS;
    const url = globConfig.url.API_URL_USERS2;
    // Request Body
    const body = JSON.stringify({ email, password });
    // console.log("in login body", body);
    axios.get(url + '/sanctum/csrf-cookie').then(response => {
        // Login... 
        // console.log(response);

        axios
            // .post("http://localhost:8080/wp-json/jwt-auth/v1/token", body, config)
            .post(url + '/login', body, config)
            .then(res => {
                console.log('res:', res);

                dispatch({
                    type: LOGIN_SUCCESS,
                    payload: res.data
                });
            })
            .catch(err => {
                //   dispatch(returnErrors(err.response.data, err.response.status));
                console.log('auth login: ', err.response.data, err.response.status);
                dispatch({
                    // type: LOGIN_FAIL,
                    type: ALERT_USER,
                    payload: { type: 'error', msg: err.response.data.message }
                });
                for (const [key, value] of Object.entries(err.response.data.errors)) {
                    // console.log(`${key}: ${value}`);
                    dispatch({
                        // type: LOGIN_FAIL,
                        type: ALERT_USER,
                        payload: { type: 'error', msg: `${key}: ${value}` }
                    });
                }
            });
    });
};

// REGISTER USER
export const register = (name, email, password, password_confirmation) => dispatch => {
    axios.defaults.withCredentials = true;
    // Headers
    const config = {
        headers: {
            'Accept': 'application/json',
            "Content-Type": "application/json",
        }
    };
    const url = globConfig.url.API_URL_USERS2;

    // const url = globConfig.url.API_URL_USERS_REGISTER;
    // http://localhost:8080/wp-json/wp/v2/users/register
    // Request Body
    const body = JSON.stringify({ name, email, password, password_confirmation });
    // console.log('register body:', body);
    axios.get(url + '/sanctum/csrf-cookie').then(response => {
        // Login... 
        // console.log(response);
        axios
            // .post("http://localhost:8080/wp-json/jwt-auth/v1/token", body, config)
            .post(url + '/register', body, config)
            .then(res => {
                // console.log(res.data);
                dispatch(login(email, password));
                // dispatch({
                //     type: REGISTER_SUCCESS,
                //     payload: res.data
                // });
            })
            .catch(err => {
                //   dispatch(returnErrors(err.response.data, err.response.status));
                // dispatch({
                //     type: REGISTER_FAIL
                // });
                console.log('auth register: ', err.response.data, err.response.status);
                dispatch({
                    type: ALERT_USER,
                    payload: { type: 'error', msg: err.response.data.message }
                });
                for (const [key, value] of Object.entries(err.response.data.errors)) {
                    // console.log(`${key}: ${value}`);
                    dispatch({
                        // type: LOGIN_FAIL,
                        type: ALERT_USER,
                        payload: { type: 'error', msg: `${key}: ${value}` }
                    });
                }
            });
    });
};

//LOGOUT
export const logout = () => dispatch => {
    axios.defaults.withCredentials = true;
    const config = {
        headers: {
            'Accept': 'application/json',
            "Content-Type": "application/json",
        }
    };
    const url = globConfig.url.API_URL_USERS2;
    const body = JSON.stringify({});
    axios.get(url + '/sanctum/csrf-cookie').then(response => { //moze to nie
        // Logout... 
        // console.log(response);
        axios
            // .post("http://localhost:8080/wp-json/jwt-auth/v1/token", body, config)
            .post(url + '/logout', body, config)
            .then(res => {
                // console.log(res.data);
                // dispatch(login(username, password));
                dispatch({
                    type: LOGIN_LOGOUT,
                    payload: res.data
                });
            })
            .catch(err => {
                //   dispatch(returnErrors(err.response.data, err.response.status));
                dispatch({
                    type: REGISTER_FAIL
                });
            });
    });

}


