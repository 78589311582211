import {
    BASKETS_LOADING,
    BASKETS_LOADED,
    CURRENT_BASKET_LOADING,
    CURRENT_BASKET_LOADED,
    CURRENT_BASKET_SAVED,
    CURRENT_BASKET_NOTSAVED,
    CURRENT_BASKET_RESET
} from '../actionsTypes';

const initialState = {
    baskets: [],
    pages: { page: 1, per_page: 10, total: "", totalpages: "" },
    // currentBasket: { basketid: '', title: '', content: '', status: "publish", fields: { "contain": [] } },
    currentBasket: { basketid: '', title: '', description: '', status: "publish", "contain": [] },
    isLoaded: false,
    isSaved: true
}


export default function (state = initialState, action) {
    switch (action.type) {
        case BASKETS_LOADING:
        case CURRENT_BASKET_LOADING:
            return {
                ...state,
                isLoaded: false
            }
        case BASKETS_LOADED:
            //console.log('reducers baskets action.payload:', action.payload)
            return {
                ...state,
                baskets: action.payload.baskets,
                pages: action.payload.pages,
                isLoaded: true
            };
        case CURRENT_BASKET_LOADED:
        case CURRENT_BASKET_SAVED:
            // console.log('reducers baskets action.payload:', action.payload)
            return {
                ...state,
                // baskets: action.payload.baskets,
                // pages: action.payload.pages,
                currentBasket: action.payload.currentBasket,
                isSaved: true,
                isLoaded: true
            };
        case CURRENT_BASKET_NOTSAVED:
            // console.log('reducers baskets action.payload:', action.payload)
            return {
                ...state,
                // baskets: action.payload.baskets,
                // pages: action.payload.pages,
                // currentBasket: action.payload.currentBasket,
                isSaved: false
                // isLoaded: true
            };
        case CURRENT_BASKET_RESET:
            // console.log('reducers baskets action.payload:', action.payload)
            return {
                ...state,
                // baskets: action.payload.baskets,
                // pages: action.payload.pages,
                currentBasket: { basketid: '', title: 'New basket', description: '', status: "publish", "contain": [] },
                // currentBasket:  action.payload.currentBasket,
                isSaved: true,
                isLoaded: true
            };
        default:
            return state;
    }
}