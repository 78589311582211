import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import Products from '../products/Products';
// import axios from 'axios';
import { saveBasket } from '../../redux/actions/Baskets';
import { ALERT_USER, CURRENT_BASKET_NOTSAVED, REDIRECT_TO } from '../../redux/actionsTypes';

export class NewEditBasketItem extends Component {
  constructor(props) {
    super(props);
    // this.state = this.props.newbasket;
    // this.state = { ...this.props.currentBasket, isSaved: true, isNewAdded: this.props.isNewAdded };
    // this.state = { ...this.props.currentBasket, isSaved: this.props.isSaved, isNewAdded: this.props.isNewAdded };
    this.state = { ...this.props.currentBasket, isNewAdded: this.props.isNewAdded, redirectToId: false };
    if (this.state.description == null) this.setState({ description: " " });
    // console.log('####### state', this.props)
  }
  // state = {
  //   ...this.state,
  //   isSaved: false
  // }
  // state = {
  //     title: 'My new basket',
  //     content: '',
  //     isSaved: false,
  //     contain: {}
  // }
  // state = this.props.newbasket;

  componentDidMount() {
    // console.log("this.state from componentDidMount in NewEditBasketItem: ", this.state, this.props.location)
  }

  onChange = (event) => {
    let nam = event.target.name;
    let val = event.target.value;
    this.setState({ [nam]: val });
    // this.setState({ isSaved: false });

    // console.log('onchange state: ', this.state)
    this.props.dispatch({ type: CURRENT_BASKET_NOTSAVED })
    // this.props.saveBasket(this.state)
  }

  addToBasket = (e) => {
    //console.log('"' + e.target.getAttribute("keyvalue") + '":"' + e.target.innerText + '"');

    let con = { ...this.state.contain }
    // let con = this.state.contain;
    // con.push('{"'+e.target.getAttribute("keyvalue")+'":"'+e.target.innerText+'"}');
    con[e.target.getAttribute("keyvalue").toString()] = e.target.innerText;
    //console.log(con);
    // //console.log(JSON.parse(con[0]));
    this.setState({ contain: con });
    // this.setState({ isSaved: false });
    this.props.dispatch({ type: CURRENT_BASKET_NOTSAVED });
    this.props.dispatch({ type: ALERT_USER, payload: { type: 'success', msg: `Added: ${e.target.innerText}` } });


  }

  removeFromBasket = (e) => {
    let con = this.state.contain;
    delete con[e.target.getAttribute("keyvalue")];
    this.setState({ contain: con });
    // this.setState({ isSaved: false });
    this.props.dispatch({ type: CURRENT_BASKET_NOTSAVED });
    this.props.dispatch({ type: ALERT_USER, payload: { type: 'info', msg: `Removed: ${e.target.innerText}` } });

  }

  onSubmit = e => {
    e.preventDefault();
    // if (!this.props.isAuthenticated) { to nie ma sensu, bo w action ma byc
    //   this.props.dispatch({
    //     type: REDIRECT_TO,
    //     payload: this.props.location.pathname
    //   });
    // }
    this.props.dispatch(saveBasket(this.state, this.props.currentBasket.basketid));
    // first check if success !
    this.setState({ basketid: this.props.currentBasket.basketid });
    if (this.state.isNewAdded) {
      this.setState({ redirectToId: true });
    }

    // console.log("NewEditBasketItem submit: some info for user should be here", this.state);
  }
  listContain = (contain) => {
    let ulist = [];
    let children = [];
    let objArr = Object.entries(contain);
    objArr.map(item => (
      children.push(<li key={item[0]} keyvalue={item[0]} onClick={this.removeFromBasket}>{item[1]}</li>)
    ))
    ulist.push(children);
    return ulist;
  }

  // nag = () => {
  //   // if (this.state.isNewAdded) {
  //   if (!this.props.basketid) {
  //     return "Add New Basket"
  //   }
  //   else {
  //     return "Edit Basket"
  //   }
  // }

  render() {
    if (!this.props.isAuthenticated) {
      this.props.dispatch({
        type: REDIRECT_TO,
        payload: window.location.pathname
      })
      return (<Redirect to='/login' />);
    }

    const { title, description, isSaved, contain, basketid } = this.state;

    // console.log('const { title, description, isSaved } = this.props.currentBasket;', this.props.currentBasket, 'state:', this.state)


    if (this.state.redirectToId && this.props.isSaved && (this.props.currentBasket.basketid !== "")) {

      const redironsave = "/baskets/" + this.props.currentBasket.basketid;
      return (<Redirect to={redironsave} />);
    }
    else {
    }

    let submitButton = "btn btn-primary"
    let submitCaption = "Submit"
    if (this.props.isSaved) {
      // const submitButton = '<button type="submit" className="btn btn-primary">Saved</button>'
      submitButton = "btn btn-primary"
      submitCaption = "Saved"
    }
    else {
      // const submitButton = '<button type="submit" className="btn btn-primary lime text-pink">Save</button>'
      submitButton = "btn btn-primary pink"
      submitCaption = "Save"
    }

    //page caption
    let pageCaption = 'Basket'
    if (this.props.currentBasket.basketid) {
      pageCaption = "Edit Basket"
    }
    else {
      pageCaption = "Add New Basket"
    }

    // let lista = '';
    // for (const property in contain) {
    //     lista = lista + `<li>${property}: ${contain[property]}</li>`
    // } 


    // //console.log(this.props.drugi)
    return (
      <div>
        {/* <h2>{this.nag()}</h2> */}
        <h4>{pageCaption}</h4>
        <form onSubmit={this.onSubmit}>
          <div className="form-group">
            <label>Name</label>
            <input
              className="form-control"
              type="text"
              name="title"
              onChange={this.onChange}
              value={title}
            />
          </div>
          <div className="form-group">
            <label>Description</label>
            <input
              className="form-control"
              type="text"
              name="description"
              onChange={this.onChange}
              value={description}
            />
          </div>
          {/* <div className="form-group">
            <label>Contain:</label>
            <textarea
              className="form-control"
              type="text"
              name="contain"
              onChange={this.onChange}
              value={JSON.stringify(contain)}
            />
          </div> */}
          <div>
            <label>Contain:</label>
            {this.listContain(contain)}
          </div>
          <hr></hr>

          <div className="form-group">
            <button type="submit" className={submitButton}>
              {submitCaption}
            </button>
          </div>
        </form>


        <div>
          <Products addToBasket={this.addToBasket} />
        </div>


      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  currentBasket: state.baskets.currentBasket,
  isSaved: state.baskets.isSaved,
  isLoaded: state.baskets.isLoaded,
  redirectFromLogin: state.auth.redirectFromLogin,
  isAuthenticated: state.auth.isAuthenticated,
  // pathname: state.location.pathname
})

// export default connect(null, { saveBasket })(NewEditBasketItem)
export default connect(mapStateToProps)(NewEditBasketItem)