import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
// import './App.css';
import Products from './components/products/Products';
import Baskets from './components/baskets/Baskets';
import NewBasketItem from './components/baskets/NewBasketItem';
import EditBasketItem from './components/baskets/EditBasketItem';
// import MasoweDodanieProduktow from './components/skrypty/MasoweDodanieProduktow';
import Header from './components/layout/Header';
import Register from './components/accounts/Register';
import Login from './components/accounts/Login';
import { Provider } from 'react-redux';
import store from './redux/store';


function App() {
  return (
    <Provider store={store}>
      <Router>
        <div>
          <header>
            <Header />
          </header>
          <div className="container">
            <Route exact path="/" component={Products} />
            <Route exact path="/baskets" component={Baskets} />
            <Route exact path="/baskets/:id" component={EditBasketItem} />
            <Route exact path="/newbasket" component={NewBasketItem} />
            {/* <Route exact path="/masowedodanieproduktow" component={MasoweDodanieProduktow} /> */}
            <Route exact path="/register" component={Register} />
            <Route exact path="/login" component={Login} />
          </div>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
