import React, { Component } from 'react';
import { connect } from 'react-redux';
import { searchProducts, fetchProducts } from '../../redux/actions/Products';

import {
    ALERT_USER,
} from "../../redux/actionsTypes";

export class SearchInput extends Component {
    state = {
        inputSearchName: ''
    }
    onChange = e => this.setState({
        [e.target.name]: e.target.value
    });
    onSearch = e => {
        e.preventDefault();
        // console.log('SearchInput submit: ', this.state.inputSearchName, typeof (this.state.inputSearchName), Boolean(this.state.inputSearchName));

        const { per_page, total } = this.props.pages;
        if (Boolean(this.state.inputSearchName)) {
            this.props.dispatch(searchProducts(per_page, 1, this.state.inputSearchName, true));
        }
        else {
            this.props.dispatch(fetchProducts(per_page, 1, true));
            // this.props.dispatch({
            //     type: ALERT_USER,
            //     payload: { type: 'info', msg: `Found ${total} products` }
            // });
        }
    };
    render() {
        const { inputSearchName } = this.state;
        const { searchName } = this.props.pages;
        return (
            <div className="product-item card" id='productSearch'>
                <form onSubmit={this.onSearch}>
                    <div className="row">
                        <div className="form-group col s10">
                            <label>Searched product name: {searchName}</label>
                            <input
                                type="text"
                                className="form-control"
                                name="inputSearchName"
                                onChange={this.onChange}
                                value={inputSearchName}
                            />
                        </div>
                        <div className="form-group col s2 right-align">
                            <button type="submit" className="btn btn-primary">
                                Search
                        </button>
                        </div>
                    </div>
                </form>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    // products: state.products.products,
    pages: state.products.pages,
    // isLoaded: state.products.isLoaded

})

export default connect(mapStateToProps)(SearchInput)