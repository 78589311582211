import React, { Component } from 'react';
// import NutrientItem from './NutrientItem';

export class BasketProductItem extends Component {
    render() {
        // const { post_title, ID } = this.props.product;
        const { id, title } = this.props.product;

        // let acfArr =  Object.entries(acf);
        return (
            <div>
                <span>| {id}: {title} |</span>|
            </div>
        )
    }

}



export default BasketProductItem
