// Constants.js
const prod = {
    url: {
        API_URL2: 'http://tomasz.podkanowicz.pl/api',
        API_URL_USERS2: 'http://tomasz.podkanowicz.pl'
    }
};
const dev = {
    url: {
        // API_URL: 'http://localhost:8080/wp-json/wp/v2',
        API_URL2: 'http://localhost:8000/api',
        // API_URL_USERS: 'http://localhost:8080/wp-json/jwt-auth/v1/token',
        API_URL_USERS2: 'http://localhost:8000',
        // API_URL_LOGIN: 'http://localhost:8000',
        // API_URL_USERS_REGISTER: 'http://localhost:8080/wp-json/wp/v2/users/register'
    }
};
// //console.log(process.env.NODE_ENV)
export const globConfig = process.env.NODE_ENV === 'development' ? dev : prod;
