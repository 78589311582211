import React, { Component } from 'react'

export class NutrientItem extends Component {
    render() {
        const nutrient = this.props.nutrient
        // //console.log(this.props.nutrient)
        if (nutrient !== undefined) {

            return (
                <span>
                    | { nutrient[0]}: { nutrient[1]} |
                </span>
            )
        }
        else {
            return <span> a </span>
        }
    }
}

export default NutrientItem
