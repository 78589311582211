import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
// import axios from 'axios';
import BasketItem from './BasketItem';
import Pagination from '../layout/Pagination';
import { fetchBaskets } from '../../redux/actions/Baskets';
import { REDIRECT_TO } from '../../redux/actionsTypes';


export class Baskets extends Component {
    constructor(props) {
        super(props);
        this.setPage = this.setPage.bind(this)
    }
    // state = {
    //     baskets: [],
    //     isLoaded: false
    // }


    // proxy z package jest ignorowane?
    componentDidMount() {
        const { per_page, page } = this.props.pages
        this.props.dispatch(fetchBaskets(per_page, page));
        // console.log('baskets componentDidMount this.props', this.props)
        // axios.get('http://localhost:8080/wp-json/wp/v2/basket')
        //     .then(res => this.setState({
        //         baskets: res.data,
        //         isLoaded: true
        //     }))
        //     .catch(err => //console.log(err));
    }

    setPage(page) {
        // let newState = this.state
        // newState.pages.page = page
        // this.setState(newState)
        // //console.log('aaa', this.state)
        // //console.log('aaa', this.props)
        const { per_page } = this.props.pages
        this.props.dispatch(fetchBaskets(per_page, page));
    }

    render() {
        // console.log('Baskets this.props', this.props);
        const { baskets, pages, isLoaded, isAuthenticated } = this.props;
        if (!isAuthenticated) {
            this.props.dispatch({
                type: REDIRECT_TO,
                payload: window.location.pathname
            });
            return (<Redirect to='/login' />);
        }
        if (isLoaded) {
            return (
                <div>
                    <h1>List of baskets</h1>
                    {
                        baskets.map(basket => (
                            // <h4>{basket.title.rendered}</h4>
                            <BasketItem key={basket.id} basket={basket}></BasketItem>
                        ))
                    }
                    <Pagination pages={pages} setPage={this.setPage} />
                </div>
            );
        }
        else {
            return (
                <div>Loading...</div>
            )
        }
    }
}

const mapStateToProps = (state) => ({
    baskets: state.baskets.baskets,
    pages: state.baskets.pages,
    isLoaded: state.baskets.isLoaded,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(Baskets)
