import axios from 'axios';
import { globConfig } from '../../Constants';




import {
    // PRODUCTS_LOADING,
    PRODUCTS_LOADED,
    ALERT_USER,
} from "../actionsTypes";

// constructor(props) {
//     super(props);
//     this.setPage = this.setPage.bind(this)
// }
// state = {
//     products: [],
//     headers: '',
//     pages: { page: 1, per_page: 20, total: "", totalpages: "" },
//     isLoaded: false
// }

// proxy z package jest ignorowane?
//fetch products
export const fetchProducts = (per_page = 15, page, alertUser = false) => dispatch => {
    // var url = globConfig.url.API_URL;
    var url = globConfig.url.API_URL2;
    // axios.get('http://localhost:8080/wp-json/wp/v2/products')
    // let query = '?per_page=' + per_page + '&page=' + page;
    let query = '?per_page=' + per_page + '&page=' + page;
    axios.get(url + '/products' + query)
        .then(res => {
            // console.log(res.data)
            let newRes = {
                products: res.data.products.data,
                pages: { page: res.data.products.current_page, per_page: res.data.products.per_page, total: res.data.products.total, totalpages: res.data.products.last_page, searchName: "" }
            }
            dispatch({
                type: PRODUCTS_LOADED,
                // payload: res.data
                payload: newRes
            });
            if (alertUser) {
                dispatch({
                    type: ALERT_USER,
                    payload: { type: 'info', msg: `Found ${res.data.products.total} products` }
                });
            }
        })
        .catch(err => {
            console.log('products get: ', err.response.data, err.response.status);

        });

    // //console.log('Products headers: ', this.state.headers)
}
// searchProducts
export const searchProducts = (per_page = 15, page, searchName, alertUser = false) => dispatch => {
    const config = {
        headers: {
            'Accept': 'application/json',
            "Content-Type": "application/json",
        }
    };
    var url = globConfig.url.API_URL2;
    const body = JSON.stringify({ name: searchName });
    // console.log(body)

    let query = '?per_page=' + per_page + '&page=' + page;

    axios.post(url + '/products' + query, body, config)
        .then(res => {
            console.log(res.data)

            let newRes = {
                products: res.data.products.data,
                pages: { page: res.data.products.current_page, per_page: res.data.products.per_page, total: res.data.products.total, totalpages: res.data.products.last_page, searchName: searchName }
            }
            dispatch({
                type: PRODUCTS_LOADED,
                // payload: res.data
                payload: newRes
            });
            if (alertUser) {
                dispatch({
                    type: ALERT_USER,
                    payload: { type: 'info', msg: `Found ${res.data.products.total} products` }
                });
            }
        })
        .catch(err => {
            console.log('products post search: ', err.response.data, err.response.status);

        });

    // //console.log('Products headers: ', this.state.headers)
}

